import type { PropsWithChildren } from 'react';

import tw from 'twin.macro';

import { Suspense } from 'react';
import Player from '@/features/bgm';
import ConditionallyRender from '@/components/ConditionallyRender';
import SiteNav from '@/components/SiteNav';
import { useReferralTrack } from '@/features/referrals/useReferalTrack';

import AppProvider from './AppProvider';
import ChakraThemeProvider from './ChakraThemeProvider';

export default function AppLayout({ children, dehydratedState }: PropsWithChildren<{dehydratedState?: unknown}>) {
  useReferralTrack();
  return (
    <AppProvider dehydratedState={dehydratedState}>
      <ChakraThemeProvider>
        <div tw="fixed w-full top-0 left-0 z-50 transition-all bg-[rgba(0,0,0,0.8)]">
          <Suspense>
            <SiteNav />
          </Suspense>
        </div>
        {children}
        <Player />
      </ChakraThemeProvider>
    </AppProvider>
  );
}
