import {useCallback} from 'react'
import {atom} from 'jotai'
import {atomWithStorage, useUpdateAtom} from 'jotai/utils'

export type TrackInfo = {
  name: string
  artist: string
  url?: string
}

const songs = [
  'https://nft-assets.phala.world/music/PhalaWorld_Lore.mp3',
  'https://nft-assets.phala.world/music/phala_world_theme.mp3',
  'https://nft-assets.phala.world/music/cybernetic_age.mp3',
]

const metadata: TrackInfo[] = [
  {
    name: 'PhalaWorld Lore',
    artist: 'Bowser Staxx',
    url: 'https://twitter.com/BowserStaxx',
  },
  {
    name: 'PhalaWorld Theme',
    artist: 'Bowser Staxx',
    url: 'https://twitter.com/BowserStaxx',
  },
  {
    name: 'Cybernetic Age',
    artist: 'Bowser Staxx',
    url: 'https://twitter.com/BowserStaxx',
  },
]

export const lastPlayStateAtom = atomWithStorage('prefs-autoplay', true)

export const audioControlsAtom = atom<HTMLAudioElement | null>(null)

const internalAudioElementDerrivedAtom = atom(
  null,
  (get, set, el: HTMLAudioElement) => {
    // NOTE We assume the Audio element never changed, or it change by
    // replace style and previous one will destroyed (so all previous event
    // listeners will be removed)

    el.addEventListener('loadeddata', () => {
      set(isLoadingAtom, false)
      set(durationAtom, el.duration)
    })

    el.addEventListener('error', () => {
      set(isErrorAtom, true)
      set(isPlayingAtom, false)
    })

    el.addEventListener('ended', () => {
      set(setTrackNumDerrivedAtom, 'next')
      set(isLoadingAtom, true)
    })

    el.addEventListener('play', () => {
      set(isPlayingAtom, true)
    })

    el.addEventListener('pause', () => {
      set(isPlayingAtom, false)
    })

    el.addEventListener('timeupdate', () => {
      set(currentTimeAtom, el.currentTime)
      set(durationAtom, el.duration)
      if (!get(isPlayingAtom)) {
        set(isLoadingAtom, false)
        set(isPlayingAtom, true)
      }
    })

    const fireAutoPlayOnce = () => {
      set(setPlayingDerrivedAtom, true)
      window.removeEventListener('click', fireAutoPlayOnce)
      window.removeEventListener('keydown', fireAutoPlayOnce)
      window.removeEventListener('touchstart', fireAutoPlayOnce)
    }

    if (get(lastPlayStateAtom)) {
      window.addEventListener('click', fireAutoPlayOnce)
      window.addEventListener('keydown', fireAutoPlayOnce)
      window.addEventListener('touchstart', fireAutoPlayOnce)
    }

    set(audioControlsAtom, el)
  }
)

export const isLoadingAtom = atom<boolean>(false)

export const isErrorAtom = atom<boolean>(false)

export const isPlayingAtom = atom<boolean>(false)

export const currentTrackAtom = atom<number>(0)

export const currentTimeAtom = atom<number>(0)

export const durationAtom = atom<number>(0)

export const currentPercentageAtom = atom((get) => {
  const percentage = get(currentTimeAtom) / get(durationAtom)
  if (isNaN(percentage)) {
    return 0
  }
  return percentage
})

export const setTrackNumDerrivedAtom = atom(
  null,
  (get, set, action: 'next' | 'prev') => {
    const currentTrack = get(currentTrackAtom)
    let nextTrack: number
    if (action === 'next') {
      nextTrack = currentTrack + 1
      if (nextTrack >= songs.length) {
        nextTrack = 0
      }
    } else if (action === 'prev') {
      nextTrack = currentTrack - 1
      if (nextTrack < 0) {
        nextTrack = songs.length - 1
      }
    } else {
      throw new Error('invalid action')
    }
    const url = songs[nextTrack]
    if (!url) {
      throw new Error('invalid url')
    }
    const el = get(audioControlsAtom)
    if (el) {
      el.src = url
      el.load()
      set(isLoadingAtom, true)
      set(currentTrackAtom, nextTrack)
    }
  }
)

export const trackInfoAtom = atom<TrackInfo>(
  (get) => metadata[get(currentTrackAtom)] as TrackInfo
)

export const setPlayingDerrivedAtom = atom(
  null,
  (get, set, next?: boolean | undefined) => {
    const el = get(audioControlsAtom)
    const current = get(isPlayingAtom)
    if (next === undefined) {
      next = !current
    }
    if (el) {
      if (next) {
        const url = songs[get(currentTrackAtom)]
        if (!el.src && url) {
          el.src = url
          el.load()
        }
        try {
          el.play()
        } catch (err) {
        }
        // gtag('event', 'player_play', {
        //   event_category: 'music',
        //   event_label: 'play',
        // })
      } else {
        el.pause()
        set(lastPlayStateAtom, false)
        // gtag('event', 'player_pause', {
        //   event_category: 'music',
        //   event_label: 'pause',
        // })
      }
    }
  }
)

export const useTogglePlay = () => {
  const setPlaying = useUpdateAtom(setPlayingDerrivedAtom)
  return useCallback(() => setPlaying(undefined), [setPlaying])
}

export const useAudioElementRef = () => {
  const setCurrentAudioElement = useUpdateAtom(internalAudioElementDerrivedAtom)
  return useCallback(
    (el: HTMLAudioElement | null) => {
      if (el) {
        setCurrentAudioElement(el)
      }
    },
    [setCurrentAudioElement]
  )
}

export const controlsVisibleAtom = atom(true)
