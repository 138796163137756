import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import {motion} from 'framer-motion'

import {
  controlsVisibleAtom,
  trackInfoAtom,
  isPlayingAtom,
  isLoadingAtom,
  setTrackNumDerrivedAtom,
  currentPercentageAtom,
  useTogglePlay,
  useAudioElementRef,
} from './atoms'
import PlayIcon from './player-play.svg'
import PauseIcon from './player-pause.svg'
import PrevIcon from './player-prev.svg'
import NextIcon from './player-next.svg'
import {AiOutlineLoading} from 'react-icons/ai'

const AsidePictureButton = styled.button`
  ${tw`z-[31] w-[120px] xl:w-[6.48vw] xl:h-[3.75vw] relative left-[-1px] top-0 pointer-events-auto`}
`

const AsidePictureImage = tw.img`select-none w-full h-full`

const AsidePicture = () => {
  const setControlsVisible = useUpdateAtom(controlsVisibleAtom)
  return (
    <AsidePictureButton
      onClick={() => {
        setControlsVisible((p) => !p)
      }}
    >
      <AsidePictureImage src="/static/images/player/player-aside.png" alt="" />
    </AsidePictureButton>
  )
}

const MusicInfoContainer = tw.div`flex flex-col text-black`

const MusicInfoHeading = tw.h4`text-sm font-primary leading-none mb-1`

const MusicInfoArtist = tw.div`text-xs leading-none`

const MusicInfoLink = tw.a`underline transition-colors hover:text-primary`

const MusicInfo = () => {
  const info = useAtomValue(trackInfoAtom)
  return (
    <MusicInfoContainer>
      <MusicInfoHeading>{info.name}</MusicInfoHeading>
      {info.url ? (
        <MusicInfoArtist>
          <MusicInfoLink href={info.url} target="_blank">
            {info.artist}
          </MusicInfoLink>
        </MusicInfoArtist>
      ) : (
        <MusicInfoArtist>{info.artist}</MusicInfoArtist>
      )}
    </MusicInfoContainer>
  )
}

const StyledPlayLoadingIcon = styled(AiOutlineLoading)`
  ${tw`text-black hover:scale-105 active:scale-95 transition-all`}
  ${tw`animate-spin absolute w-6 h-6 top-4 left-4`}
`

const StyledPauseIcon = styled(PauseIcon)`
  ${tw`text-black hover:scale-105 active:scale-95 transition-all`}
`

const StyledPlayIcon = styled(PlayIcon)`
  ${tw`text-black hover:scale-105 active:scale-95 transition-all`}
`

const StyledPrevIcon = styled(PrevIcon)`
  ${tw`text-black hover:scale-105 active:scale-95 transition-all`}
`

const StyledNextIcon = styled(NextIcon)`
  ${tw`text-black hover:scale-105 active:scale-95 transition-all`}
`

const PlayStateIcon = () => {
  const isPlaying = useAtomValue(isPlayingAtom)
  const isLoading = useAtomValue(isLoadingAtom)
  if (isLoading) {
    return <StyledPlayLoadingIcon />
  }
  return isPlaying ? <StyledPauseIcon /> : <StyledPlayIcon />
}

const ControlsContainer = tw.div`flex justify-center items-center px-2`

const PlayerButton = styled.button<{secondary?: boolean}>`
  ${tw`m-0 p-0 w-8 h-8 disabled:opacity-50`}
  ${({secondary}) => secondary && tw`overflow-hidden h-[32px] relative`}
`

const Controls = () => {
  const toggle = useTogglePlay()
  const setTrackNum = useUpdateAtom(setTrackNumDerrivedAtom)
  const isLoading = useAtomValue(isLoadingAtom)
  return (
    <ControlsContainer>
      <PlayerButton
        onClick={() => {
          setTrackNum('prev')
        }}
        disabled={isLoading}
      >
        <StyledPrevIcon />
      </PlayerButton>
      <PlayerButton secondary onClick={toggle}>
        <PlayStateIcon />
      </PlayerButton>
      <PlayerButton
        onClick={() => {
          setTrackNum('next')
        }}
        disabled={isLoading}
      >
        <StyledNextIcon />
      </PlayerButton>
    </ControlsContainer>
  )
}

const Audio = () => {
  const ref = useAudioElementRef()
  return <audio autoPlay ref={ref} style={{display: 'hidden'}} />
}

const ProgressBarInner = styled.div<{percentage: number}>`
  ${tw`w-full h-1 absolute left-0 -top-0.5 rounded-full`}
  ${({percentage}) =>
    `background: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${percentage}, #36FAD7), color-stop(${percentage}, transparent));`}
`

const ProgressBar = () => {
  const currentPercentage = useAtomValue(currentPercentageAtom)
  return <ProgressBarInner percentage={currentPercentage} />
}

const ToggleableAreaContainer = styled(motion.div)`
  ${tw`h-14 xl:h-[2.92vw] flex flex-row justify-center items-center flex-1 bg-white pl-2 relative`}
`

const ToggleableAreaBackground = tw.div`absolute h-14 w-[6.48vw] xl:h-[2.92vw] bg-white z-0 bottom-0 left-[-6.48vw]`

const ToggleableArea = () => {
  const controlsVisible = useAtomValue(controlsVisibleAtom)
  return (
    <ToggleableAreaContainer
      animate={{
        transform: controlsVisible ? 'translateX(-100%)' : 'translateX(0.001%)',
        opacity: controlsVisible ? 0 : 1,
        pointerEvents: controlsVisible ? 'none' : 'auto',
      }}
      transition={{
        type: 'spring',
        stiffness: 700,
        damping: 30,
      }}
    >
      <ToggleableAreaBackground />
      <ProgressBar />
      <MusicInfo />
      <Controls />
    </ToggleableAreaContainer>
  )
}

const PlayerContainer = tw.div`fixed bottom-[1.25vw] left-[1.25vw] z-[60] flex flex-row justify-center items-end overflow-x-hidden pointer-events-none`

const Player = () => {
  return (
    <PlayerContainer>
      <AsidePicture />
      <ToggleableArea />
      <Audio />
    </PlayerContainer>
  )
}

export default Player
