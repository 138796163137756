import { useEffect } from "react";
import { useRouter } from "next/router";
import * as R from 'ramda';
import { setCookie } from "cookies-next";

export function useReferralTrack() {
  const router = useRouter();
  const ref_code = R.path(['query', 'ref_code'], router)
  useEffect(() => {
    if (ref_code) {
      setCookie('ref_code', ref_code, { maxAge: 60 * 60 * 24 * 5 })
    }
  }, [ref_code])
}